<script setup lang="ts">
import {
  createColumnHelper,
  simpleTableVariant,
} from '@ui/components/SimpleTable'
import { tv } from 'tailwind-variants'
import type { ISummaryTableField } from '../types'

const props = defineProps<{ data: ISummaryTableField[] }>()

const columnHelper = createColumnHelper<ISummaryTableField['value']>()
const columns = [
  columnHelper('rent', {
    header: 'Rent',
  }),
  columnHelper('original', {
    header: 'Original',
    cell: (value) => value ?? '-',
  }),
  columnHelper('current', {
    header: 'Current',
    cell: (value) => value ?? '-',
  }),
]
const data = computed(() =>
  props.data.map((item) => ({
    rent: item.displayName,
    original: item.value.original.value,
    current: item.value.current.value,
  })),
)

const variant = tv({
  extend: simpleTableVariant,
  slots: {
    thead: '!border-none bg-gray-775',
    theadTr: 'bg-inherit text-sm',
    theadTh: 'text-sm font-normal text-gray-500',
    tbody: '!divide-none',
    tbodyTr: 'bg-bg-inherit',
    tbodyTd: [
      'text-sm',
      'font-normal',
      'text-white',
      'data-[column=rent]:text-base',
      'data-[column=rent]:font-bold',
    ],
  },
})
</script>

<template>
  <div class="p-2 md:p-4 lg:p-6">
    <div class="overflow-auto lg:max-h-[350px] print:max-h-[unset]">
      <SimpleTable
        v-if="data.length > 0"
        :columns
        :data
        :hoverable="false"
        :variant="variant"
      />
      <div v-else class="w-full text-center">No annual charges</div>
    </div>
  </div>
</template>
